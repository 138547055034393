.container-drag-and-drop {
  padding: -3rem 0rem;
}

.box-hover {
  cursor: pointer;
  outline: 0;
}

.box-hover:hover {
  outline: 2px solid $primary-color;
}

.page-hover:hover {
  outline: 1px solid $secondary-color;
  border-radius: 10px;
  transition: .4s;
}

.sider-style {
  overflow: scroll;
  max-height: 100vh;
  position: relative;
  background-color: #f1f1f1 !important;
  padding: 30px,
}

.pdf-viewer {
  width: 100% !important;
  min-height: 200vh !important;
  overflow: auto !important;
}

.pdf-preview {
  top: 20px !important;
  width: 100% !important;
}

.layout-style {
  max-height: 100vh;
  overflow: hidden !important;
}

.content-style {
  margin: 24px 10px 0;

}

#tools {
  min-width: 30%;
}