.getstart {
  background-color: $primary-color;
  transition: .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.getstart:hover {
  gap: 1.5rem;
  opacity: 0.9;
  background-color: $primary-color;
}

@media only screen and (max-width: 520px) {
  .featureHover:hover {
    transform: scale(1);
  }
}