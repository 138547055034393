@import url('https://fonts.googleapis.com/css2?family=Kantumruy+Pro:ital,wght@0,100..700;1,100..700&display=swap');

@import 'Variable';
@import 'GetStartButtonStyle';
@import 'FuntionalPageStyle';
@import 'DragAndDrop';

* {
  margin: 0;
  padding: 0;
  font-family: $font-kantumruy;
  box-sizing: border-box;
}

.text-primary-color {
  color: $primary-color !important;
}

.text-secondary-color {
  color: $secondary-color !important;
}

.text-thrid-color {
  color: $third-color !important;
}

.text-fourth-color {
  color: $fourth-color !important;
  font-size: 18px;
}

.background-primay-color {
  background-color: $primary-color !important;
}

.background-secondary-color {
  background-color: $secondary-color !important;
}

.border-primary-color {
  border: $primary-color solid 1px;
}

.background-third-color {
  background-color: $third-color !important;
}

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

// magin style block: 
.mt-n1 {
  margin-top: -0.25rem !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dropdown-menu.show {
  min-width: fit-content;
}

.custom-style::after {
  display: none !important;
}

.hover-item:hover {
  opacity: 1 !important;
}

.clipboard {
  border: 1px solid #1C4076 !important;
}


.fit-width {
  width: fit-content;
}

.btn-designer {
  border: 2px solid #1C4076 !important;
  transition: .4s;
  width: 43px;
  height: 43px;
}

.btn-designer:hover {
  background-color: $primary-color !important;
  color: white !important;
}

.input-designer {
  border-color: #1C4076 !important;
  ;
}

.images-logo:hover {
  cursor: pointer !important;
  opacity: 0.9;
}

.btn-text-color {
  color: $primary-color !important;
}

.dropdown-nav:hover {
  opacity: 0.8 !important;
  transition: .4s;
}

.card-color {
  background-color: #ebf5ff !important;
}

.bg-section {
  background-color: #fafafa !important;
}

.login-button {
  min-width: 100px;
}

.logo-min-width {
  min-width: 35px;
}

@media only screen and (max-width: 768px) {
  .login-button {
    min-width: auto;
  }
  .fit-width {
    width: 100%;
  }
}

.footer-logo {
  max-width: 170px;
}
.sectionHeight {
  min-height: 100vh;
  width: auto;
}