.animated-hover-template-list:hover {
  opacity: 0.8;
  cursor: pointer;
}

.box-upload {
  margin: 3rem 1rem;
  padding: 1.5rem;
  border-radius: 10px;
  border: 2px dashed $fourth-color;
  text-align: center;
}

.main-upload {
  width: 75%;
}

.main-upload:hover {
  opacity: 0.8;
}

.bg-upload {
  background-color: rgb(218, 232, 249);
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with 50% opacity */
  z-index: 9999;
  /* Ensure it's above other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-drop-hover {
  transition: .4s;
}

.file-drop-hover:hover {
  outline: 1.5px dashed $primary-color;
  color: $primary-color;
}

.innter-upload-file {
  opacity: .8;
  padding: 6rem 1rem;
  background-color: rgba(28, 64, 108, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

@media only screen and (max-width: 580px) {
  .main-upload {
    width: 100%;
  }
}

.max-highth-50 {
  max-height: 500px !important;
  overflow-y: scroll !important;
}
.hover-box {
  transition: .4s;
}

.hover-box:hover {
  background-color: $primary-color;
  color: white !important;
}
.logo-navbar {
  width: 140px !important;
}
@media only screen and (max-width: 1920px) {
  .logo-navbar{
    width:65% ;
  }
}

@media only screen and (max-width: 768px) {
  .logo-navbar{
    width: 90%;
  }
}

.btu-login{
  width: 120px;
}

@media only screen and (max-width: 768px ) {
  .btu-login {
    display:none !important;
  }
}

@media only screen and (max-width: 1920px) {
  .btu-login-user {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  .btu-login-user{
    display: block !important;
    width: 47px;
  }
}

.privacy-policy{
  max-width: 85ch !important;
  padding: 0 20px !important;
}

.title-privacy-policy{
  font-size: 48px !important;
  font-weight: 300;
}

.bg-app-layout-global{
  background-color: rgb(239, 243, 255);
}

ul,li{
  font-weight: 460 !important;
  font-size: 17px !important;
}

.url-contact-dgc{
  color: #1C4076;
}

.terms-title{
  font-weight: 600 !important;
  font-size: 20px !important;
  padding-left: 20px;
}

.terms-description{
  font-weight: 460 !important;
  font-size: 17px !important;
  color: rgb(32, 32, 32);
}
.ant-modal-confirm-btns {
  text-align: center;
  display: flex;
  width: 100%;
}

.ant-modal-confirm-title {
  text-align: center;
}
.ant-modal-confirm-content {
  text-align: center;
}
.ant-modal-content {
  padding: 1rem !important;
  border-radius: 12px !important;
}
.ant-modal{
  width: 500px !important;
}
.ant-btn-default {
  width: 100%;
}
.ant-btn-primary {
  width: 100%;
  background-color: $primary-color;
}
.loading{
  font-size: 35px !important;
  color: #1C4076;
}